import React from "react";
import { Col } from "react-bootstrap";

export default class ProjectItem extends React.Component {
  render() {
    const { title, href, description, tech } = this.props;
    return (
      <Col style={{ margin: "auto" }} lg={11}>
        <h3>
          <a rel="noopener noreferrer" target="_blank" href={href}>
            {title}
          </a>
        </h3>
        <p>{description}</p>
        <h4>Technologies</h4>
        <ul>
          {tech.split(",").map(t => (
            <li>{t}</li>
          ))}
        </ul>
      </Col>
    );
  }
}
