import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Music from "./pages/Music";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, ButtonGroup, Button } from "react-bootstrap";
import logo from "./images/GarrettLajinessLogo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import { parseString } from "xml2js";
import { Howl, Howler } from "howler";
import Work from "./pages/Work";

class App extends React.Component {
  navbarBottom;
  musicPage;
  songs;
  state = {
    loading: true,
    audio: {
      playing: false,
      track: null,
      buttonText: "Play",
      currentSong: null,
      currentIndex: 0
    }
  };
  show = () => {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  };
  hide = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
  };
  playPauseHandler = () => {
    if (this.state.audio.playing) {
      this.sound.pause();
      this.setState({
        audio: {
          playing: false,
          track: this.getTrackName(this.songs[this.state.audio.currentIndex]),
          buttonText: "Play",
          currentIndex: this.state.audio.currentIndex
        }
      });
    } else {
      this.sound.play();
      this.setState({
        audio: {
          playing: true,
          track: this.getTrackName(this.songs[this.state.audio.currentIndex]),
          buttonText: "Pause",
          currentIndex: this.state.audio.currentIndex
        }
      });
    }
  };
  pause = () => {
    this.sound.pause();
  };
  stop = () => {
    this.sound.stop();
    this.setState({
      audio: {
        track: this.state.audio.track,
        playing: false,
        buttonText: "Play",
        currentIndex: this.state.audio.currentIndex
      }
    });
  };
  changeVolume = event => {
    Howler.volume(event.target.value);
  };
  prev = event => {
    this.setState({
      audio: {
        track: this.getTrackName(this.songs[this.state.audio.currentIndex - 1]),
        currentIndex: (this.state.audio.currentIndex -= 1),
        playing: this.state.audio.playing,
        buttonText: this.state.audio.buttonText
      }
    });
    this.sound.stop();
    this.sound = new Howl({
      src: [
        "https://salvador.nyc3.digitaloceanspaces.com/" +
          this.songs[this.state.audio.currentIndex]
      ]
    });
    if (this.state.audio.playing) {
      this.sound.play();
    }
  };
  next = event => {
    this.setState({
      audio: {
        track: this.getTrackName(this.songs[this.state.audio.currentIndex + 1]),
        currentIndex: (this.state.audio.currentIndex += 1),
        playing: this.state.audio.playing,
        buttonText: this.state.audio.buttonText
      }
    });
    this.sound.stop();
    this.sound = new Howl({
      src: [
        "https://salvador.nyc3.digitaloceanspaces.com/" +
          this.songs[this.state.audio.currentIndex]
      ],
      onend: this.next
    });
    if (this.state.audio.playing) {
      this.sound.play();
    }
  };
  getAlbumName(string) {
    return string.split("/")[1];
  }
  getTrackName(string) {
    return string.split("/")[2].replace(/\.mp3/, "");
  }
  playTrack(event) {
    this.setState({
      audio: {
        track: this.getTrackName(
          this.songs[this.songs.indexOf(event.target.dataset.songTitle)]
        ),
        currentIndex: this.songs.indexOf(event.target.dataset.songTitle),
        playing: this.state.audio.playing,
        buttonText: this.state.audio.buttonText
      }
    });
    this.sound.stop();
    this.sound = new Howl({
      src: [
        "https://salvador.nyc3.digitaloceanspaces.com/" +
          this.songs[this.songs.indexOf(event.target.dataset.songTitle)]
      ],
      onend: this.next
    });
    if (this.state.audio.playing) {
      this.sound.play();
    }
  }
  componentDidMount() {
    axios
      .get("https://salvador.nyc3.digitaloceanspaces.com/")
      .then(response => {
        parseString(response.data, (err, results) => {
          this.songs = results.ListBucketResult.Contents.map(
            track => track.Key[0]
          ).filter(track => {
            return track.indexOf(".mp3") > -1;
          });
          this.sound = new Howl({
            src: [
              "https://salvador.nyc3.digitaloceanspaces.com/" + this.songs[0]
            ],
            onend: this.next
          });
          this.setState({
            audio: {
              playing: false,
              track: this.getTrackName(this.songs[0]),
              buttonText: "Play",
              currentIndex: this.state.audio.currentIndex
            }
          });
          this.show();
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  render() {
    if (this.state.loading) {
      this.musicPage = <p>Loading...</p>;
      this.navbarBottom = (
        <Navbar
          bg="dark"
          variant="dark"
          style={{ color: "white" }}
          className="justify-content-center"
          fixed="bottom"
        >
          <div>
            <h1>Loading...</h1>
          </div>
        </Navbar>
      );
    } else {
      this.navbarBottom = (
        <div>
          <Navbar
            style={{
              display: "absolute",
              bottom: "95px",
              backgroundColor: "white",
              borderTop: "1px solid black"
            }}
            className="justify-content-center"
            fixed="bottom"
          >
            <Navbar.Text>{this.state.audio.track}</Navbar.Text>
          </Navbar>
          <Navbar
            bg="dark"
            variant="dark"
            className="justify-content-center"
            fixed="bottom"
          >
            <ButtonGroup className="mt-3">
              <Button
                style={{ padding: "20px" }}
                variant="secondary"
                onClick={this.prev}
              >
                &#8592;
              </Button>

              <Button
                style={{ padding: "20px" }}
                variant="secondary"
                onClick={this.playPauseHandler}
              >
                {this.state.audio.buttonText}
              </Button>
              <Button
                style={{ padding: "20px" }}
                variant="secondary"
                onClick={this.next}
              >
                &#8594;
              </Button>
            </ButtonGroup>
          </Navbar>
        </div>
      );
      this.musicPage = (
        <Music playTrack={event => this.playTrack(event)} songs={this.songs} />
      );
    }
    return (
      <div>
        <Router>
          <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Brand as="div">
                <Link to="/">
                  <img height="50px" src={logo} alt="Garrett Lajiness logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                  <Link className="nav-link" to="/work">
                    Work
                  </Link>
                  <Link className="nav-link" to="/music">
                    Music
                  </Link>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/Work">
                <Work/>
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/music">
                {this.musicPage}
              </Route>
            </Switch>
          </div>
        </Router>
        {/* <Player /> */}
        {this.navbarBottom}
      </div>
    );
  }
}

export default App;
