import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from "../images/Garrett.jpeg";
import icon from "../images/linkedin.svg";

class AboutPage extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: "160px" }}>
        <h1>About</h1>
        <Row>
          <Col lg={5}>
            <div style={{ textAlign: "center" }}>
              <img
                style={{
                  borderRadius: "100px",
                  border: "2px solid",
                  borderColor: "#212529",
                }}
                src={image}
                alt="Garrett headshot"
              ></img>
              <div
                style={{
                  margin: "1em",
                  position: "relative",
                  bottom: "50px",
                  left: "50px",
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/glajiness/"
                >
                  <img src={icon} alt="linkedin logo"></img>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <Container>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <p>
                  I have a passion for creating things.{" "}
                  <Link to="/contact">Contact me</Link> to ask about my
                  professional experience
                </p>
                <h3>
                  <Link to="/work">Personal projects</Link>
                </h3>
                <h3>
                  <Link to="/music">Music</Link>
                </h3>
                <h3>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://bluelambcreative.com/"
                  >
                    Art
                  </a>
                </h3>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AboutPage;
