import React from "react";
import ProjectItem from "../components/Project";
import { Link } from "react-router-dom";

class ProjectsPage extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: "10em" }}>
        <h1>Work</h1>
        <p style={{ marginLeft: "3%" }}>
          Some personal projects put together in my spare time.{" "}
          <Link to="/contact">Contact me</Link> to ask me about my professional
          experience.
        </p>
        <ProjectItem
          title="Hot Dice"
          href="https://stark-chamber-41575.herokuapp.com"
          tech="Nodejs, Socket.io, Sass, Heroku"
          description="Multiplayer Hotdice game that uses websockets to combat bordem during COVID 19 quarantine."
        />
        <ProjectItem
          title="Blue Lamb Creative Website"
          href="http://bluelambcreative.com"
          tech="Gatsby, Google Sheets, Sass, s3"
          description="Website that displays our painting inventory from Google sheets."
        />
        <ProjectItem
          title="Sound Generator"
          href="https://noize.garrettlajiness.com"
          tech="React, Material Design, Howler js, Digitalocean Spaces"
          description="Ambient noise generator designed mainly for sleeping babies."
        />
      </div>
    );
  }
}

export default ProjectsPage;
