import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import axios from "axios";
class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
    };
  }
  contact;
  formHandler(event) {
    let formBody = {};
    event.preventDefault();
    this.setState({
      loading: true,
    });
    event.target.querySelectorAll("input,textarea").forEach(element => {
      formBody[
        element.id.replace(/formGroup/, "").toLowerCase()
      ] = element.value.trim();
    });
    console.log(formBody);
    axios({
      method: "POST",
      url:
        "https://pacific-falls-65539.herokuapp.com?email=glajiness@gmail.com",
      data: formBody,
    })
      .then(response => {
        this.setState({
          loading: false,
          submitted: true,
        });
      })
      .catch(err => {
        console.error(err);
      });

    console.log(this.state);
  }
  componentDidMount() {}
  render() {
    if (this.state.loading) {
      this.contact = (
        <p style={{ textAlign: "center", marginTop: "250px" }}>Sending...</p>
      );
    } else if (this.state.submitted) {
      this.contact = (
        <p style={{ textAlign: "center", marginTop: "250px" }}>
          Thank you for submitting!
        </p>
      );
    } else {
      this.contact = (
        <div style={{ marginBottom: "12em" }}>
          <h1>Contact</h1>
          <Col style={{ margin: "auto" }} lg={11}>
            <Form onSubmit={e => this.formHandler(e)}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" required={true} />
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" required={true} />
              </Form.Group>
              <Form.Group controlId="formGroupBody">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  style={{ height: "200px" }}
                  as="textarea"
                  type="text"
                  required={true}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </div>
      );
    }
    return this.contact;
  }
}

export default ContactPage;
