import React from "react";
import { Col, Image } from "react-bootstrap";

export default class TechItem extends React.Component {
  render() {
    return (
      <Col style={{ margin: "auto" }} xs={6} md={4}>
        <Image
          style={{ width: "100%", padding: "1em" }}
          src={this.props.src}
          alt={this.props.altText}
        />
      </Col>
    );
  }
}
