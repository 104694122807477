import React from "react";
import { ListGroup } from "react-bootstrap";

class MusicPage extends React.Component {
  player;
  playHandler(player) {
    console.log(player);
  }
  render() {
    return (
      <div>
        <h1>
          Music -{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://greproductions.bandcamp.com/"
          >
            Download
          </a>
        </h1>
        <div id="music-list" style={{ marginBottom: "160px" }}>
          <ListGroup style={{ margin: "1em" }} variant="flush">
            {this.props.songs.map((song, key) => (
              <ListGroup.Item
                onClick={event => this.props.playTrack(event)}
                key={key}
                data-song-title={song}
              >
                {song.split("/")[2].replace(/.mp3$/, "")}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
    );
  }
}

export default MusicPage;
