import React from "react";
import { Jumbotron, Container, Row, Button } from "react-bootstrap";
import TechItem from "../components/TechItem";
import nodeLogo from "../images/590px-Node.js_logo.png";
import mongoLogo from "../images/MongoDB_Logo.png";
import expressLogo from "../images/Expressjs.png";
import reactLogo from "../images/react-logo.png";
import dockerLogo from "../images/docker-logo.png";
import kubernetesLogo from "../images/kubernetes-logo.png";
import htmlCssJsLogo from "../images/html-css-js-logos.png";
import pythonLogo from "../images/python-logo.png";
import postgresLogo from "../images/postgres-logo.png";
import { Link } from "react-router-dom";
class HomePage extends React.Component {
  render() {
    return (
      <div style={{ paddingBottom: "10em" }}>
        <Jumbotron fluid>
          <Container>
            <h2>Full-Stack Developer</h2>
            <p>
              Garrett Lajiness is a application developer who uses the latest
              technologies to make websites. Reach out to me if you are looking
              for someone to work on your next project.
            </p>

            <Link to="/contact">
              <Button variant="dark">Contact me</Button>
            </Link>
          </Container>
        </Jumbotron>
        <Container>
          <h2>Technologies</h2>
        </Container>
        <div style={{ textAlign: "center" }}>
          <Container>
            <Row>
              <TechItem
                src={htmlCssJsLogo}
                altText="html css and javascript logo"
              />
              <TechItem src={nodeLogo} altText="node js logo" />
              <TechItem src={expressLogo} altText="express logo" />
            </Row>
          </Container>
          <Container>
            <Row>
              <TechItem src={mongoLogo} altText="mongo db logo" />
              <TechItem src={dockerLogo} altText="docker logo" />
              <TechItem src={reactLogo} altText="react logo" />
            </Row>
          </Container>
          <Container>
            <Row>
              <TechItem src={kubernetesLogo} altText="kubernetes logo" />
              <TechItem src={pythonLogo} altText="python logo" />
              <TechItem src={postgresLogo} altText="postgres logo" />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default HomePage;
